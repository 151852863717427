// frontend/src/components/PatientListTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

const PatientList = () => {
    const [patients, setPatients] = useState([]);

    useEffect(() => {
        // 患者情報をバックエンドから取得
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/patients`)
            .then(response => {
                setPatients(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the patients!', error);
            });
    }, []);

    const columns = [
        { field: 'patient_number', headerName: '患者番号', width: 150 },
        { field: 'name', headerName: '名前', width: 150 },
        { field: 'birthdate', headerName: '生年月日', width: 150 },
        { field: 'gender', headerName: '性別', width: 100 },
        { field: 'postal_code', headerName: '郵便番号', width: 130 },
        { field: 'address', headerName: '住所', width: 200 },
        { field: 'doctor_name', headerName: '主治医', width: 150 },
        { field: 'severity', headerName: '重症度', width: 100 },
        { field: 'frequency', headerName: '頻度', width: 100 },
        { field: 'unavailable_dates', headerName: '訪問NG日', width: 200 },
        { field: 'status', headerName: 'ステータス', width: 100 },
        ];

    return (
        <div>
            <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                <DataGrid
                    rows={patients}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                />
            </div>
        </div>
    );
};

export default PatientList;
