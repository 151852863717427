import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/css/Header.css';

const Header = ({ showSidebar }) => {
    const { t } = useTranslation();
    // const { i18n } = useTranslation();
    // const [language, setLanguage] = useState(i18n.language);

    // const changeLanguage = (lng) => {
    //     i18n.changeLanguage(lng);
    //     setLanguage(lng);
    // };
    return (
        <header className="header">
            <div className="mobile-header">
                <Link to="/" className="logo-link">{t('siteName')}</Link>
                <div className="language-dropdown-header">
                <Link to="/login" className="">{t('login')}</Link>
                    {/* <select
                        value={language}
                        onChange={(e) => changeLanguage(e.target.value)}
                        name='language'
                    >
                        <option value="en">English</option>
                        <option value="ja">日本語</option>
                    </select> */}
                </div>
            </div>
        </header>
    );
};

export default Header;
