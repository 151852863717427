// frontend/src/pages/PatientList.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PatientListTable from '../components/PatientListTable';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const PatientList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAdd = () => {
        navigate('/patients/add');
    };

    return (
        <div>
        <h2>{t('patientList')}</h2>
            <Button variant="contained" color="primary" onClick={handleAdd}>
                {t('addPatient')}
            </Button>
        <PatientListTable />
        </div>
    );
};

export default PatientList;
