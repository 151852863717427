// frontend/src/components/ManualAddPatient.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ManualAddPatient = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        patient_number: '',
        name: '',
        birthdate: '',
        gender: '',
        postal_code: '',
        address: '',
        doctor_id: null,  // 初期値をnullに設定
        severity: '',
        frequency: '',
        unavailable_dates: '',
        status: '',
    });
    const [doctors, setDoctors] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/staff/list/doctors`)
            .then(response => {
                console.log('Fetched doctors:', response.data);
                setDoctors(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the doctors!', error.response || error.message);
            });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        const dataToSubmit = {
            ...formData,
            birthdate: formData.birthdate || null,
            business_id: formData.business_id ? Number(formData.business_id) : null,
            doctor_id: formData.doctor_id ? Number(formData.doctor_id) : null,  // nullチェックを追加
            severity: formData.severity ? Number(formData.severity) : null,
            postal_code: formData.postal_code ? Number(formData.postal_code) : null,
        };
    
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/patients/`, dataToSubmit)
            .then(response => {
                console.log('Patient added successfully:', response.data);
                navigate('/patients');
            })
            .catch(error => {
                console.error('There was an error adding the patient!', error.response || error.message);
            });
    };

    return (
        <div>
            <h2>{t('manualAdd')}</h2>
            <form noValidate autoComplete="off">
                <TextField
                    label={t('patientNumber')}
                    name="patient_number"
                    value={formData.patient_number}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('patientName')}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('birthdate')}
                    name="birthdate"
                    type="date"
                    value={formData.birthdate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label={t('gender')}
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('postalCode')}
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('address')}
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    select
                    label={t('attendingDoctor')}
                    name="doctor_id"
                    value={formData.doctor_id || ''}  // 初期値を''に設定
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                >
                    {doctors.map((doctor) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                            {doctor.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={t('severity')}
                    name="severity"
                    value={formData.severity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('frequency')}
                    name="frequency"
                    value={formData.frequency}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('unavailableDates')}
                    name="unavailable_dates"
                    value={formData.unavailable_dates}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={t('status')}
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {t('submit')}
                </Button>
            </form>
        </div>
    );
};

export default ManualAddPatient;
