// src/components/Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <div>
        <main>
            <div><a href='/patients'>{t('patientList')}</a></div>
            <div><a href='/csv-extractor'>{t('csvExtractor')}</a></div>
        </main>
        </div>
    );
};

export default HomePage;
