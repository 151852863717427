// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: #00a410;
    color: #e1e9fc;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed; /* ヘッダーを固定 */
    top: 0; /* ヘッダーをページの上部に配置 */
    width: 100%; /* ヘッダーをページの幅に合わせる */
    z-index: 1000; /* ヘッダーを最前面に表示 */
}

.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo-link {
    color: #e1e9fc;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    
}

.nav-icon {
    font-size: 2rem;
    cursor: pointer;
}

.language-dropdown-header {
    margin-left: auto;
    margin-right: 30px;
}

.language-dropdown-header select {
    background-color: transparent;
    border: none;
    color: #e1e9fc;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px;
}

.language-dropdown-header select option {
    background-color: #00a410;
    color: #e1e9fc;
}

`, "",{"version":3,"sources":["webpack://./src/styles/css/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe,EAAE,YAAY;IAC7B,MAAM,EAAE,mBAAmB;IAC3B,WAAW,EAAE,oBAAoB;IACjC,aAAa,EAAE,gBAAgB;AACnC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,iBAAiB;;AAErB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,cAAc;IACd,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".header {\n    background-color: #00a410;\n    color: #e1e9fc;\n    padding: 16px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    position: fixed; /* ヘッダーを固定 */\n    top: 0; /* ヘッダーをページの上部に配置 */\n    width: 100%; /* ヘッダーをページの幅に合わせる */\n    z-index: 1000; /* ヘッダーを最前面に表示 */\n}\n\n.mobile-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.logo-link {\n    color: #e1e9fc;\n    text-decoration: none;\n    font-size: 24px;\n    font-weight: bold;\n    \n}\n\n.nav-icon {\n    font-size: 2rem;\n    cursor: pointer;\n}\n\n.language-dropdown-header {\n    margin-left: auto;\n    margin-right: 30px;\n}\n\n.language-dropdown-header select {\n    background-color: transparent;\n    border: none;\n    color: #e1e9fc;\n    font-size: 1rem;\n    cursor: pointer;\n    padding: 5px;\n}\n\n.language-dropdown-header select option {\n    background-color: #00a410;\n    color: #e1e9fc;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
