import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ManualAddPatient from '../components/ManualAddPatient';
import BulkAddPatient from '../components/BulkAddPatient';
import { useTranslation } from 'react-i18next';

const AddPatient = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <h2>{t('addPatients')}</h2>
            <BulkAddPatient />
            <ManualAddPatient />
            <Button variant="contained" onClick={() => navigate('/patients')}>
                {t('backToList')}
            </Button>
        </div>
    );
};

export default AddPatient;
