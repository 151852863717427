// frontend/src/components/CSVExtractor.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const CSVExtractor = () => {
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [downloadLinks, setDownloadLinks] = useState([]);
    const [encoding, setEncoding] = useState("utf-8-sig");
    const [isLoading, setIsLoading] = useState(false);  // ロード中かどうかの状態

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleEncodingChange = (e) => {
        setEncoding(e.target.value);
    };

    const handleFileUpload = async () => {
        if (!file) {
            setMessage("Please select a CSV file to upload.");
            return;
        }

        setIsLoading(true);  // アップロード開始時にロード状態をオンにする
        setMessage("");  // メッセージをクリア
        setDownloadLinks([]);  // ダウンロードリンクをクリア

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/extract-csv/?output_encoding=${encoding}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setDownloadLinks(response.data.files);
            setMessage("Patient information processed successfully!");

        } catch (error) {
            setMessage(`Error: ${error.response?.data?.detail || "File upload failed."}`);
        } finally {
            setIsLoading(false);  // アップロードが完了したらロード状態をオフにする
        }
    };

    const handleDownload = (encodedName) => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/download-file/${encodedName}`, {
            responseType: 'blob'  // バイナリレスポンスを期待
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', encodedName);  // ファイル名を指定
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            setMessage(`Error downloading ${encodedName}: ${error.response?.data?.detail}`);
        });
    };

    return (
        <div>
            <h1>{t('csvExtractor')}</h1>
            <input type="file" onChange={handleFileChange} />
            <div>
                <label>{t('selectEncoding')}</label>
                <select onChange={handleEncodingChange} value={encoding}>
                    <option value="utf-8-sig">UTF-8</option>
                    {/* <option value="shift_jis">Shift-JIS</option>
                    <option value="cp932">CP932</option> */}
                </select>
            </div>
            <button onClick={handleFileUpload} disabled={isLoading}>
                {isLoading ? t('uploading') : t('upload')}
            </button>
            {isLoading && <p>{t('loading')}...</p>}  {/* ロード中のメッセージを表示 */}
            {message && <p>{message}</p>}
            {downloadLinks.length > 0 && (
                <div>
                    <h3>{t('downloadCSV')}</h3>
                    <ul>
                        {downloadLinks.map((file) => (
                            <li key={file.encoded_name}>
                                <button onClick={() => handleDownload(file.encoded_name)}>
                                    {file.display_name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CSVExtractor;
