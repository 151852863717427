// src/components/BusinessRegistration.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BusinessRegistration = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/businesses/`, {
        name,
        address,
        phone,
      });
      setMessage('Business registered successfully!');
      navigate('/');
    } catch (error) {
      setMessage('Error registering business.');
      console.error('Error creating business:', error.response ? error.response.data : error);
    }
  };

  return (
    <div>
      <h2>{t('businessRegistration')}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>{t('name')}:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>{t('address')}:</label>
          <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </div>
        <div>
          <label>{t('phoneNumber')}:</label>
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <button type="submit">{t('register')}</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default BusinessRegistration;
