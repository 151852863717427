import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BulkAddPatient = () => {
    const { t } = useTranslation();
    const [csvFile, setCsvFile] = useState(null);

    const onDrop = (acceptedFiles) => {
        setCsvFile(acceptedFiles[0]);
    };

    const handleUpload = () => {
        if (!csvFile) return;

        const formData = new FormData();
        formData.append('file', csvFile);

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/patients/bulk`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log('File uploaded successfully', response.data);
                // ここでリダイレクトするか、メッセージを表示
            })
            .catch(error => {
                console.error('There was an error uploading the file!', error);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div>
            <h2>{t('bulkAdd')}</h2>
            <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #0087F7', padding: '20px', marginBottom: '20px' }}>
                <input {...getInputProps()} />
                <p>{t('dragDropCSV')}</p>
            </div>
            <Button variant="contained" color="primary" onClick={handleUpload}>
                {t('uploadCSV')}
            </Button>
        </div>
    );
};

export default BulkAddPatient;
